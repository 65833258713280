@import "./common/base.scss";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~react-phone-number-input/style.css";
@import '~react-tagsinput/react-tagsinput.css';
@import "~react-toastify/dist/ReactToastify.css";
@import '../fonts/index.scss';
@import "./components/index.scss";
@import "./layouts/index.scss";
@import "./pages/index.scss";


@keyframes blinking {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
